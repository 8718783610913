import Vue from "vue";
import Web3 from "web3";
import { Contract } from "web3-eth-contract";

import { Currency, Address, IndexedCurrency } from '@/types';

export type NotLoadedState = {
  isReady: false;

  web3?: Web3;
  defaultAccount?: Address;
  swapContract?: Contract;
  swapContractAddress?: Address;
  proxyContract?: Contract;
  rewardsContract?: Contract;
  rewardsContractAddress?: Address;
  dutchAuction?: Contract;
  dutchAuctionAddress?: Address;
  lotteryContract?: Contract;
  sigToken?: Currency;
  coins: IndexedCurrency[];
  ethPrice: any;
  transactionPending: any;
}

export type LoadedState = {
  isReady: true;
  
  web3: Web3;
  defaultAccount: Address;
  swapContract: Contract;
  swapContractAddress: Address;
  proxyContract: Contract;
  rewardsContract: Contract;
  rewardsContractAddress: Address;
  dutchAuction: Contract;
  dutchAuctionAddress: Address;
  lotteryContract: Contract;
  sigToken: Currency;
  coins: IndexedCurrency[];
  ethPrice: any;
  transactionPending: any;
};

export type State = LoadedState | NotLoadedState;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isLoadedState = (state: State): state is LoadedState => { return true }

const state = Vue.observable<State>({
    defaultAccount: undefined,
    // web3: undefined,
    // swapContract: undefined,
    ethPrice: 0,
    coins: [],
    isReady: false,
    transactionPending: false
});

export const setCoins = (coins: IndexedCurrency[]) => {
  state.coins.length = 0;
  state.coins.push(...coins);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).state = state;

export default state;
