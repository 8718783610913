import { DirectiveOptions } from 'vue'

export const clickOutside: DirectiveOptions = {
    bind: (el, binding) => {
        (el as any).clickOutsideEvent = (event: Event) => {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target as Node))) {
                // and if it did, call method provided in attribute value
                binding.value();
            }
        };

        document.addEventListener("click", (el as any).clickOutsideEvent);
    },
    unbind: (el) => {
        document.removeEventListener("click", (el as any).clickOutsideEvent);
    },
};

export default clickOutside;