import Vue from "vue"
import Vuex from "vuex"
import BigNumber from "bignumber.js"

import { fetchTokenPriceToETH } from "../../crypto"

import state from "@/contract"
import init, { connectWallet, changeWallet, initToken } from "@/init"
import { retry, tryElse, fetchETHPrice } from "@/helpers"
import { ETH_PRICE_ESTIMATE } from "@/constants"
import { CONTRACT, provider, selectedNetwork } from "@/network"

import { getClaimInfo } from '@/services/airdrop/api';
import { checkClaim } from '@/services/airdrop/crypto';
import { Fraction } from "@uniswap/sdk"
import { ETH_MAINNET } from "@/address"

Vue.use(Vuex);

export default {
    async GET_BALANCE(context: any) {
        if (!state.isReady) { return }

        const m = state.swapContract.methods;
        const DAI = await m.balances(0).call();
        const USDC = await m.balances(1).call();
        const USDT = await m.balances(2).call();

        context.commit('setBalance', { coinId: 0, balance: DAI });
        context.commit('setBalance', { coinId: 1, balance: USDC });
        context.commit('setBalance', { coinId: 2, balance: USDT });

        context.commit('setTotal')
        context.commit('setBalanceChartData')

        await context.dispatch('INIT_DATA')
        // await context.dispatch('CHECK_UNCLAIMED_SIG');
    },
    async CONNECT_WALLET({ dispatch, commit }: any) {
        const web3 = await connectWallet();
        commit('setLoading', true);
        await init(web3);

        dispatch('GET_BALANCE');
    },
    async CHANGE_WALLET({ dispatch, commit }: any) {
        const web3 = await changeWallet();
        commit('setLoading', true);
        await init(web3);
        dispatch('GET_BALANCE');
    },
    async INIT_DATA(context: any) {
        if (!state.isReady) { return }

        // await context.dispatch('GET_24_VOLUME')
        await context.dispatch('GET_TOTAL_VOLUME')
        await context.dispatch('UPDATE_BALANCES')

        const a = await state.swapContract.methods.A().call()
        const fee = await state.swapContract.methods.fee().call() / 1e10 * 100;
        const adminFee = await state.swapContract.methods.admin_fee().call() / 1e10 * fee;
        const virtualPrice = await tryElse(() => state.swapContract?.methods.get_virtual_price().call())

        context.commit('setA', a)
        context.commit('setFee', fee)
        context.commit('setAdminFee', adminFee)
        context.commit('setVirtualPrice', new BigNumber(virtualPrice).div(1e18).toNumber())

        // TODO: update periodically:
        await context.dispatch('GET_GAS_PRICE')

        await context.dispatch('GET_SIG_TOKEN')
        await context.dispatch('GET_3POOL_LIQUIDITY_TOKEN')

        // TODO: update periodically:
        await context.dispatch('GET_SIG_TOKEN_PRICE')
    },
    async UPDATE_BALANCES({ commit }: any) {
        let totalBalance = new BigNumber(0)
        const balances: { [key: string]: BigNumber } = {}

        await Promise.all(state.coins.map(async coin => {

            const balance = await coin.contract.methods.balanceOf(state.defaultAccount).call();
            const formatted = new BigNumber(balance).div(coin.precision);

            // TODO: store BigNumber in state and only convert for output
            balances[coin.address] = formatted;

            totalBalance = totalBalance.plus(formatted);
        }))

        commit('setClientTotalBalance', totalBalance)
        commit('setClientBalances', balances)

        // TODO: remove? not used anywhere
        // commit('setClientTotalSupply', totalSupply)
    },
    async GET_GAS_PRICE({ commit }: any) {
        // TODO: refactor so we dont set state.isReady UNTIL gasPrice is known. So the app only shows when data is available
        // ALSO, update every few minutes

        if (!state.isReady) { return }

        // TOOD: refactor
        if (selectedNetwork.chainId !== ETH_MAINNET) {
            const gasPriceEstimate = await state.web3.eth.getGasPrice()

            const gasPrice = Number(gasPriceEstimate)

            commit('setGasPrice', {
                slow: gasPrice / 1e9,
                standard: gasPrice / 1e9,
                fast: gasPrice / 1e9,
                instant: gasPrice / 1e9,
            })

            return
        }

        try {
            const url = 'https://blockscout.com/eth/mainnet/api/v1/gas-price-oracle'

            const data = await retry(() => fetch(url)).then(res => res.json())
            // {
            // "average": 116.1796524184,
            // "fast": 142.493016231,
            // "slow": 105.64195263075
            // }

            console.log('fetched gasPrice', data)

            commit('setGasPrice', {
                slow: data.slow,
                standard: data.average,
                fast: data.average,
                instant: data.fast,
            })
        }
        catch (err) {
            console.error(err)
        }
    },
    async GET_3POOL_LIQUIDITY_TOKEN({ commit }: any) {
        if (state.isReady) {
            const liquidity3PoolToken = await initToken(state.web3, CONTRACT.xsigmaLpToken);

            const lpTokenBalance = await liquidity3PoolToken.contract.methods.balanceOf(state.defaultAccount).call();

            const balance = new BigNumber(lpTokenBalance).div(liquidity3PoolToken.precision);

            commit('setLiquidity3PoolToken', balance);
        }

    },
    async GET_SIG_TOKEN({ commit }: any) {
        if (!state.isReady) {
            return
        }
        const sigTokenBalance = await state.sigToken.contract.methods.balanceOf(state.defaultAccount).call();
        const totalSupply = await state.sigToken.contract.methods.totalSupply().call();

        const balance = new BigNumber(sigTokenBalance).div(state.sigToken.precision);
        const supply = new BigNumber(totalSupply).div(state.sigToken.precision);

        /* eslint-disable @typescript-eslint/camelcase */
        const FIX_totalSupply = new BigNumber(supply).times(70).div(100);

        commit('setSigTokenBalance', balance);
        commit('setSigTokenTotalSupply', FIX_totalSupply);

    },
    async CHECK_UNCLAIMED_SIG({ commit }: any) {
        if (!state.isReady) {
            return
        }

        if (!state.defaultAccount) {
            return;
        }
        if (!state.web3) {
            return;
        }

        try {
            const claims = await getClaimInfo(state.defaultAccount);

            console.log('claims', claims);

            const allAirdrops = await Promise.all(
                claims
                    .filter(claim => claim.token.toLowerCase() === CONTRACT.sigToken.toLowerCase())
                    .map(async claim => {
                        try {
                            return {
                                ...claim,
                                isClaimed: await checkClaim(
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    state.web3!,
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    state.defaultAccount!,
                                    claim
                                ),
                            };
                        } catch (err) {
                            console.error('error checking claim', claim, err);
                            return { ...claim, isClaimed: true }
                        }
                    })
            );

            console.log('airdrops', allAirdrops)

            const unclaimedAirdrops = allAirdrops.filter(
                claim => !claim.isClaimed
            ).sort((a, b) => {
                return new BigNumber(b.amount).minus(a.amount).toNumber();
            })

            console.log('unclaimed', unclaimedAirdrops);

            const balance = unclaimedAirdrops.reduce((acc, claim) => {
                return acc.plus(claim.amount);
            }, new BigNumber(0));

            console.log('balance', balance);

            if (balance.gt(0)) {
                commit('setUnclaimedSigTokenBalance', balance.div(state.sigToken.precision));
            }
            commit('setAvailableSigTokenClaims', unclaimedAirdrops);
        } catch (err) {
            console.error(err)
        }
    },
    async GET_24_VOLUME({ commit }: any) {
        const url = '/api/volume'

        const { volume, weekDailyAverage } = await fetch(url).then(res => res.json())

        console.log('the 24h volume', volume);
        console.log('the 24h weekly averaged volume', weekDailyAverage);

        try {
            commit('set24Volume', weekDailyAverage);
        } catch (e) {
            console.log(e)
        }

    },
    async GET_TOTAL_VOLUME({ commit }: any) {
        const url = '/api/volume/raw'

        try {
            const { data } = await fetch(url).then(res => res.json())

            const { usdVolume } = data.exchangeDatas[0]

            console.log('total volume', usdVolume);

            commit('setTotalVolume', usdVolume);
        } catch (e) {
            console.log(e)
        }
    },
    async GET_SIG_TOKEN_PRICE({ commit }: any) {
        if (!state.isReady) { return }

        try {
            const info = await fetchTokenPriceToETH(provider, state.sigToken)

            console.log('info', info)

            // if you multiply this by ETH price, you will get SIG price in $
            console.log('SIG/ETH price', info.toSignificant(6))

            const { current_price: ETH_PRICE } = await tryElse(() => fetchETHPrice(), ETH_PRICE_ESTIMATE)

            const ethPrice = new BigNumber(ETH_PRICE);

            console.log('eth price', ethPrice);

            const sigPrice = info.raw
                .multiply(ethPrice.times(1e6).toFixed(0))
                .divide(1e6);

            console.log('SIG =', sigPrice.toSignificant(6), '$');
            console.log('SIG =', sigPrice.toFixed(2), '$');

            commit('setSigTokenPrice', sigPrice.toSignificant(6))
        } catch (err) {
            console.error('Error fetching SIG price')
            throw err;
        }

    }
}