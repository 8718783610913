import Web3 from "web3";
import Onboard from "bnc-onboard";
import { WalletSelectModuleOptions } from "bnc-onboard/dist/src/interfaces";
import { AbiItem } from 'web3-utils';

import state, { LoadedState, setCoins } from "./contract";

import DUTCH_AUCTION from "../abis/PeriodicDutchAuction.json";
import SIG_MASTER_CHEF from "../abis/SigMasterChef.json";
import THREE_POOL from "../abis/ThreePool.json";
import THREE_POOL_PROXY from "../abis/SigThreePoolProxy.json";
import LOTTERY_FEE from "../abis/LotteryFee.json";

// v2: BSC, Polygon etc
import DUTCH_AUCTION_V2 from "../abis/v2/PeriodicDutchAuction.json";
import SIG_MASTER_CHEF_V2 from "../abis/v2/SigMasterChefV2.json";
import THREE_POOL_PROXY_V2 from "../abis/v2/SigThreePoolProxyV2.json";

import { N_COINS, ONBOARD_API_KEY } from "@/constants";
import { selectedNetwork, switchMetamaskNetwork, provider, CONTRACT, RPC_URL, isBSC } from "@/network";

import { ONBOARD_CONFIG_WALLETS } from "@/onboard-config";

import { Address, IndexedCurrency } from "@/types";
import { calculateAPYPool1, calculateAPYPool2 } from "../crypto";
import { initToken } from "../crypto/initToken";
export { initToken }

let web3: Web3;

export const USE_V2 = (chainId: number) => isBSC(chainId) // Polygon, Optimism later

const onboard = Onboard({
    dappId: ONBOARD_API_KEY, // [String] The API key created by step one above
    networkId: selectedNetwork.chainId, // [Integer] The Ethereum network ID your Dapp uses.
    walletSelect: {
      wallets: ONBOARD_CONFIG_WALLETS,
    } as WalletSelectModuleOptions,
    subscriptions: {
        address: (newAddress: Address) => {
            state.defaultAccount = newAddress;
        },
        balance: newBalance => {
            // TODO: check for new token balances and update state
            console.log('new balance event', newBalance)

        },
        wallet: wallet => {
            console.log('selected wallet', wallet)

              // state.contract.web3 = window.web3 = new Web3(wallet.provider);
              // state.contract.walletName = wallet.name;

            web3 = new Web3(wallet.provider);

            if (wallet.name)
                localStorage.setItem("selectedWallet", wallet.name);

            if (wallet.provider)
                switchMetamaskNetwork(wallet.provider, selectedNetwork.chainId);
        }
    }
});

export const connectWallet = async () => {
    const selectedWallet = localStorage.getItem("selectedWallet");

    const isWallet = await onboard.walletSelect(selectedWallet || undefined);

    if (isWallet) {
        const isSuccess = await onboard.walletCheck();

        if (!isSuccess) {
            // if can't connect, forget Selected Wallet
            localStorage.removeItem("selectedWallet");
        }
    } else {
        web3 = new Web3(new Web3.providers.HttpProvider(RPC_URL));
    }

    // //  Enable session (triggers QR Code modal)
    // await provider.enable();
    //
    // //  Create Web3
    //
    // // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const web3 = new Web3(provider as any);
    //
    // // (window as any).web3 = web3;
    //
    //  Get Accounts
    const accounts = await web3.eth.getAccounts();
    //
    // //  Get Network Id
    //
    // // eslint-disable-next-line
    // const networkId = await web3.eth.net.getId();

    state.web3 = web3;

    console.log("accounts", accounts);
    console.log("defaultAccount", accounts[0]);

    state.defaultAccount = accounts[0];

    return web3;
};

export const changeWallet = async () => {
    // forget Selected Wallet
    localStorage.removeItem('selectedWallet');
    await onboard.walletSelect();
    await onboard.walletCheck();
    const accounts = await web3.eth.getAccounts();
    state.web3 = web3;
    state.defaultAccount = accounts[0];
    return web3;
}

export const calculateAPY = async (poolIndex: number) => {
    if (!state.isReady) { return }

    const { web3, sigToken, rewardsContract, rewardsContractAddress, swapContract } = state

    if (poolIndex === 1) {
        return calculateAPYPool1(web3, provider, sigToken, rewardsContract, rewardsContractAddress, swapContract)
    } else if (poolIndex === 2) {
        return calculateAPYPool2(web3, provider, sigToken, rewardsContract, rewardsContractAddress)
    } else {
        // return calculateAPYPool3(web3, provider, sigToken, rewardsContract, rewardsContractAddress)
        return calculateAPYPool2(web3, provider, sigToken, rewardsContract, rewardsContractAddress)
    }
}

export const fetchAPY = async (poolIndex: number) => {
    try {
        const url = selectedNetwork.chain === 'ETH'
            ? `/api/apy/pool/${poolIndex}`
            : `/api/apy/bsc/pool/${poolIndex}`

        const { apy } = await fetch(url).then(res => res.json())

        return apy
    } catch (err) {
        console.error('No APY')
        return null
    }
}

export default async (web3: Web3): Promise<LoadedState> => {
    console.log("init contracts");
    console.time("init contracts");

    try {

        const SIG_MASTER_CHEF_ABI = USE_V2(selectedNetwork.chainId) ? SIG_MASTER_CHEF_V2.abi : SIG_MASTER_CHEF.abi
        const THREE_POOL_PROXY_ABI = USE_V2(selectedNetwork.chainId) ? THREE_POOL_PROXY_V2.abi : THREE_POOL_PROXY.abi
        const DUTCH_AUCTION_ABI = USE_V2(selectedNetwork.chainId) ? DUTCH_AUCTION_V2.abi : DUTCH_AUCTION.abi

    const swapContractAddress = CONTRACT.xsigma3poolDelegator;

    const swapContract = new web3.eth.Contract(THREE_POOL.abi as AbiItem[], swapContractAddress);
    const proxyContract = new web3.eth.Contract(THREE_POOL_PROXY_ABI as AbiItem[], swapContractAddress);

    const rewardsContract = new web3.eth.Contract(SIG_MASTER_CHEF_ABI as AbiItem[], CONTRACT.sigMasterChef);

    console.log('rewards', (rewardsContract as any)._address);

    const lotteryContract = USE_V2(selectedNetwork.chainId)
        ? new web3.eth.Contract(LOTTERY_FEE.abi as AbiItem[], CONTRACT.lottery)
        : undefined;

    console.log('lotteryConntract', (lotteryContract as any)?._address);

    const dutchAuction = USE_V2(selectedNetwork.chainId)
        ? new web3.eth.Contract(DUTCH_AUCTION_ABI as AbiItem[], CONTRACT.periodicDutchAuction)
        : undefined;

    console.log('dutchAuction', (dutchAuction as any)?._address);

    // TODO: move LP token init here, so we don't re-init on each balance update (see actions.ts)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    console.log('sigToken', CONTRACT.sigToken);

    const sigToken = await initToken(web3, CONTRACT.sigToken);

    console.log('sigToken', sigToken.address);

    const coins: IndexedCurrency[] = [];

    for (let index = 0; index < N_COINS; index++) {
      console.log('index', index);

      const tokenAddress = await swapContract.methods.coins(index).call();
      console.log('address', tokenAddress);

      const coin = await initToken(web3, tokenAddress, index) as IndexedCurrency;

      coins.push(coin);
    }

    state.swapContract = swapContract;
    state.proxyContract = proxyContract;
    state.swapContractAddress = swapContractAddress;

    state.rewardsContract = rewardsContract;
    state.rewardsContractAddress = CONTRACT.sigMasterChef;

    state.dutchAuction = dutchAuction;
    state.dutchAuctionAddress = CONTRACT.periodicDutchAuction;

    state.lotteryContract = lotteryContract;

    state.sigToken = sigToken;

    setCoins(coins);

    // await fetchTokenPairETH(sigToken);


    state.isReady = true;

    console.timeEnd("init contracts");

    (window as any).calculateAPY = calculateAPY
    // await calculateAPY(1)
    // await calculateAPY(2)

    return (state as LoadedState);

    } catch (err) {
        console.log('Error at init', err.message);

        return Promise.reject(err);
    }
};
