<template>
    <b-container class="my-4 mb-5" >
        <div class="breadcrumbs">
            <span v-for="(page, index) in pages" :key="index" :class="{ active: page.isActive }">
                <span v-if="page.title === 'Home'">
                    <span class="link" @click="goHome"> {{ page.title }}</span><span class="bc-divider" v-if="pages.length !== index+1"></span>
                </span>
                <span v-if="page.title !== 'Home' && !page.url"> {{ page.title }}</span>
                <router-link class="link-with-url" v-if="page.url" :to="page.url">{{ page.title }}</router-link>
                <span class="bc-divider" v-if="pages.length !== index+1"> - </span>
            </span>
        </div>
    </b-container>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component({
    name: "Breadcrumbs",
    props: {
        pages: Array
    },
    methods: {
        goHome () {
            this.$router.push('/')
        }
    }
})
export default class Breadcrumbs extends Vue {}
</script>

<style lang="scss" scoped>
@import "src/assets/variables";
.breadcrumbs {
    font-weight: 600;
    font-size: 12px;
    color: #A7A7A7;
    .link {
        color: #A7A7A7;
        cursor: pointer;
        text-decoration: none;
    }
    .active {
        color: $black;
    }
    .link-with-url {
        color: #625a69;
        text-decoration: none;
    }
}
</style>