

















































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import BigNumber from "bignumber.js";
import {mapGetters, mapState} from "vuex";
import filtersMixin from "@/mixins/filters";

import state, { isLoadedState } from "@/contract";
import { PLACEHOLDER_CURRENCIES_INFO } from "@/coins";
import { GasPrice, GasPriceInfo } from "@/types";
import { checkOrApprove, timeout, getEthPrice, getImgUrl } from "@/helpers";
import { selectedNetwork } from "@/network";

import Alert from "@/components/UI/Alert.vue";
import Popup, { TRANSACTION_STATUS } from "@/components/UI/Popup.vue";

@Component({
    name: "Exchange",
    mixins: [filtersMixin],
    components: {
        vSelect,
        Alert,
    },
    data: () => ({
        coins: state.coins
    }),
    computed: {
        ...mapGetters([
            'getGasPriceOptions',
        ]),
        ...mapState([
            'client',
        ])
    },
    methods: {
        getImgUrl,
        compareMaxValues(balance, amount) {
            return new BigNumber(balance).toNumber() !== new BigNumber(amount).toNumber()
        }
    }
})
export default class Exchange extends Vue {
    public client!: any;
    public $amplitude!: any;

    private errors: { [key: string]: string } = {
        from: '',
        currencies: '',
    };

    private isAdvancedOptionsOpened = false;
    private isExchangePending = false;

    getGasPriceOptions!: GasPriceInfo[];
    private gasOptionsSelect = { label: '', value: new BigNumber(0) };

    private currencies = PLACEHOLDER_CURRENCIES_INFO;

    private base = this.currencies[0];
    private quote = this.currencies[1];

    private maxSlippagePercent = 0.5; // TODO: change from field

    private sellAmount = '1.00';
    private buyAmount = 0;
    private cashbackEther = 0;

    private isInfinite = true;
    private isCashbackActive = false;

    private buyAmountInput = '';
    private sellAmountInput: any = '1.00';

    private exchangeRate = 0;

    // private ethPrice = 400; // TODO: fetch ETH price
    private ethPrice = 0;
    private gasLimit = 300_000;
    private USE_GAS_LIMIT = 300_000; // updated for exchange2

    private showAlert = false;
    private transactionError = false;
    private alertText = '';
    private alertTitle = '';

    private delayTimer: any;

    async created () {
        await getEthPrice(selectedNetwork.chain)

        this.ethPrice = state.ethPrice;
    }

    async mounted () {
        while (!state.web3 || !state.isReady) {
            // DON'T RENDER AT ALL
            console.log("waiting 1 sec")
            await timeout(1000);
        }

        this.currencies = state.coins;

        this.base = this.currencies[0];
        this.quote = this.currencies[1];

        // we need to take last so transactions are mined fast
        this.gasOptionsSelect = this.getGasPriceOptions.slice(-1)[0];

        this.updateBuyAmount();

        this.$amplitude.setUserId(state.defaultAccount);
        this.$amplitude.setUserProperties({ chainId: selectedNetwork.chainId, host: location.hostname });
        this.$amplitude.logEvent('APP_LOADED');
    }

    get gasPrice (): GasPrice {
        return new BigNumber(this.gasOptionsSelect.value)
    }

    get txCostUSD () {
        return (this.gasPrice.toNumber() * this.gasLimit * this.ethPrice) / 1e9;
    }

    get exchangeRateStr () {
        return this.exchangeRate ? this.exchangeRate.toFixed(4) : '~';
    }

    async getCashbackEther () {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }
        if (this.gasPrice.isNaN()) { throw new Error("Not loaded gas price yet") }

        console.log('cashback data', this.base.index, new BigNumber(this.sellAmountInput).times(this.base.precision).toFixed(0), this.gasPrice.times(1e9).toFixed(0))

        try {
            const cashbackEther = await state.proxyContract.methods
                .calcCashbackEther(this.base.index, new BigNumber(this.sellAmountInput).times(this.base.precision).toFixed(0), this.gasPrice.times(1e9).toFixed(0))
                .call({ from: state.defaultAccount });

            console.log('cashback', cashbackEther, 'wei')

            this.cashbackEther = new BigNumber(cashbackEther).div(1e18).toNumber() // wei to ETH
        } catch (e) {
            console.log('e', e)
            this.cashbackEther = 0
        }
    }

    @Watch('getGasPriceOptions')
    onPropertyChanged(value: GasPriceInfo[]) {
        console.log('getGasPriceOptions', this.getGasPriceOptions)

        // code duplication here, but it's ok
        this.gasOptionsSelect = value.slice(-1)[0];
    }

    checkOnErrors() {
        // if (!this.base) { return false }
        const userBalanceSelectedToken = this.$store.state.client.balances[this.base.address]
        return new BigNumber( this.sellAmountInput ).gt( userBalanceSelectedToken );
    }

    openAdvOptions() {
        this.isAdvancedOptionsOpened = !this.isAdvancedOptionsOpened;
    }

    checkValid(inpNum = 0) {
        for(const index in this.errors) {
            this.errors[index] = ''
        }
        let isValid = true;
        const sellAmountInput: number = parseFloat(this.sellAmountInput);
        const { base, quote } = this;

        if (base.index === quote.index) {
            if (inpNum === 1) {
                switch (base.index) {
                    case 0: this.quote = this.currencies[1]; break;
                    case 1: this.quote = this.currencies[2]; break;
                    case 2: this.quote = this.currencies[0]; break;
                }
            }
            if (inpNum === 2) {
                switch (base.index) {
                    case 0: this.base = this.currencies[1]; break;
                    case 1: this.base = this.currencies[2]; break;
                    case 2: this.base = this.currencies[0]; break;
                }
            }
        }
        if (!sellAmountInput && !(sellAmountInput > 0)) {
            this.buyAmountInput = '';
            this.errors.from = 'Amount must be greater than 0'
            isValid = false;
        }
        return isValid;
    }

    reverseCurrency () {
        const { base, quote } = this;
        this.quote = base
        this.base = quote

        this.sellAmountInput = this.buyAmountInput;

        this.updateBuyAmount();
    }

    updateSetMaxSellAmount () {
        const { base, client } = this
        // TODO: fetch this from contract
        const decimals = base.index === 0 ? 18 : 6;
        this.sellAmountInput = client.balances[base.address].toFixed(decimals,1)
        this.updateBuyAmount()
    }

    async updateBuyAmount(inpNum = 0) {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }
        if (!this.checkValid(inpNum)) { this.exchangeRate = 0; return; }

        // TODO: restore cashback when contract supports it
        // try {
        //     await this.getCashbackEther();
        // } catch (err) {
        //     console.log('Not loaded cashback data yet');
        //     return
        // }

        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
            if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

            const { base, quote } = this;
            const sellAmount = new BigNumber(+this.sellAmountInput * base.precision);

            const dx = sellAmount.toNumber();
            const dy = await state.swapContract?.methods
              .get_dy(base.index, quote.index, sellAmount.toFixed(0,1))
              .call();

            this.exchangeRate = (dy / quote.precision) / (dx / base.precision);
            this.buyAmountInput = (dy / quote.precision).toString();

            const gasLimit = await state.swapContract?.methods
                .exchange(base.index, quote.index, sellAmount.toFixed(0,1), 0)
                .estimateGas({ from: state.defaultAccount });

            this.gasLimit = gasLimit || this.USE_GAS_LIMIT

            console.log('estimated gas limit', this.gasLimit)

        }, 1000);
    }

    async runExchange () {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }
        if (!this.checkValid() || this.checkOnErrors()) { this.exchangeRate = 0; return; }
        if (this.isExchangePending) return;

        const { base, quote } = this;

        const isInfinite = this.isInfinite;

        // We sell BASE for QUOTE, 100 DAI -> 101 USDC

        const sellAmount = new BigNumber(+this.sellAmountInput * base.precision);

        const dy = await state.swapContract.methods
          .get_dy(base.index, quote.index, sellAmount.toFixed(0,1))
          .call();

        const coin = await state.swapContract.methods.coins(base.index).call();

        const gasPrice = this.gasPrice.times(1e9)

        // waiting until all tokens are approved
        this.alertTitle = '';
        this.alertText = TRANSACTION_STATUS.checkingForApprove;
        this.showAlert = true;

        try {
            await checkOrApprove(coin, state.swapContractAddress, sellAmount, isInfinite, gasPrice);
        } catch (e) {
            this.$notify(Popup.plain.error(TRANSACTION_STATUS.approveFailed, e.message));
            return
        } finally {
            this.showAlert = false;
        }

        console.log('slippage %', this.maxSlippagePercent)
        console.log('slippage', (100 - this.maxSlippagePercent) / 100)
        console.log('dy', dy)

        const minBuyAmount = new BigNumber(dy)
            .times(100 - this.maxSlippagePercent)
            .div(100);

        console.log('minBuyAmount', minBuyAmount.toFixed(0))

        // TODO: run estimateGas
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const gasLimit = this.gasLimit
        let result;
        try {
            this.alertTitle = TRANSACTION_STATUS.waitingForConfirmationTitle;
            this.alertText = TRANSACTION_STATUS.waitingForConfirmationText;
            this.showAlert = true;

            if (this.isCashbackActive) {
                // we use proxyContract ABI, because "exchange2" doesn't exist on 3pool
                result = state
                    .proxyContract.methods
                    .exchange2(
                        base.index, quote.index,
                        sellAmount.toFixed(0,1), minBuyAmount.toFixed(0)
                    )
                    .send({
                        from: state.defaultAccount,
                        gasPrice,
                        gasLimit: this.USE_GAS_LIMIT,
                    })
            } else {
                result = state.swapContract.methods
                  .exchange(
                    base.index, quote.index,
                    sellAmount.toFixed(0,1), minBuyAmount.toFixed(0)
                  )
                  .send({
                      from: state.defaultAccount,
                      gasPrice,
                      gasLimit: this.USE_GAS_LIMIT,
                  })
            }

            result.on('transactionHash', (hash: string) => {
                console.log('tx hash', hash)
                this.alertTitle = '';
                this.alertText = TRANSACTION_STATUS.created;
                this.showAlert = true;
                this.isExchangePending = true;
                setTimeout(() => {
                    this.showAlert = false
                }, 1400)
                state.transactionPending = true;
            })
            result.on('receipt', (receipt: any) => {
                console.log('tx hash', receipt.transactionHash)

                this.$notify(Popup.tx.success(TRANSACTION_STATUS.exchanged, receipt.transactionHash))

                this.isExchangePending = false;
                state.transactionPending = false;

                this.sellAmount = '1.00';
                this.buyAmount = 0;

                this.$store.dispatch('UPDATE_BALANCES')
            })
            result.on('error', (error: any) => {
                console.error('error', error)
                state.transactionPending = false;
                this.isExchangePending = false;
                this.transactionError = true
                this.showAlert = true;
                this.alertText = TRANSACTION_STATUS.stakeReverted
                setTimeout(() => {
                    this.showAlert = false
                    this.transactionError = false
                }, 2000)
            })
        } catch (e) {
            this.alertText = TRANSACTION_STATUS.stakeReverted;
            this.showAlert = true;
        } finally {
            this.$amplitude.logEvent('RUN_EXCHANGE');
        }
    }
}
