<template>
  <div>
    <div class="cssload-conveyor">
      <span></span><span></span><span></span><span></span><span></span>
    </div>
    <div class="overlay"></div>
  </div>
</template>

<script>
    export default {
        name: "Preloader"
    }
</script>

<style scoped>
  .cssload-conveyor{
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:250px;
    z-index: 9999;
  }

  .cssload-conveyor, .cssload-conveyor * {
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .cssload-conveyor span {
    display: inline-block;
    height: 50px;
    width: 50px;
    background: rgb(228,204,237);
    transform-origin: 0 50%;
    -o-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
    -moz-transform-origin: 0 50%;
    transform-perspective: 225px;
    -o-transform-perspective: 225px;
    -ms-transform-perspective: 225px;
    -webkit-transform-perspective: 225px;
    -moz-transform-perspective: 225px;
  }

  .cssload-conveyor span:nth-child(1) {
    animation: cssload-cuve 1.3s 0.65s infinite;
    -o-animation: cssload-cuve 1.3s 0.65s infinite;
    -ms-animation: cssload-cuve 1.3s 0.65s infinite;
    -webkit-animation: cssload-cuve 1.3s 0.65s infinite;
    -moz-animation: cssload-cuve 1.3s 0.65s infinite;
  }
  .cssload-conveyor span:nth-child(2) {
    animation: cssload-cuve 1.3s 1.3s infinite;
    -o-animation: cssload-cuve 1.3s 1.3s infinite;
    -ms-animation: cssload-cuve 1.3s 1.3s infinite;
    -webkit-animation: cssload-cuve 1.3s 1.3s infinite;
    -moz-animation: cssload-cuve 1.3s 1.3s infinite;
  }
  .cssload-conveyor span:nth-child(3) {
    animation: cssload-cuve 1.3s 1.95s infinite;
    -o-animation: cssload-cuve 1.3s 1.95s infinite;
    -ms-animation: cssload-cuve 1.3s 1.95s infinite;
    -webkit-animation: cssload-cuve 1.3s 1.95s infinite;
    -moz-animation: cssload-cuve 1.3s 1.95s infinite;
  }
  .cssload-conveyor span:nth-child(4) {
    animation: cssload-cuve 1.3s 2.6s infinite;
    -o-animation: cssload-cuve 1.3s 2.6s infinite;
    -ms-animation: cssload-cuve 1.3s 2.6s infinite;
    -webkit-animation: cssload-cuve 1.3s 2.6s infinite;
    -moz-animation: cssload-cuve 1.3s 2.6s infinite;
  }
  .cssload-conveyor span:nth-child(5) {
    animation: cssload-cuve 1.3s 3.25s infinite;
    -o-animation: cssload-cuve 1.3s 3.25s infinite;
    -ms-animation: cssload-cuve 1.3s 3.25s infinite;
    -webkit-animation: cssload-cuve 1.3s 3.25s infinite;
    -moz-animation: cssload-cuve 1.3s 3.25s infinite;
  }



  @keyframes cssload-cuve {
    0% {
      transform: rotateY(-90deg) perspective(113px);
      background: rgb(241,105,167);
    }
    50% {
      transform: rotateY(0deg);
      background: rgb(206,129,237);
    }
    100% {
      transform: rotateY(90deg) perspective(113px);
      transform-origin: 100% 50%;
      background: rgb(241,105,167);
    }
  }

  @-o-keyframes cssload-cuve {
    0% {
      -o-transform: rotateY(-90deg) perspective(113px);
      background: rgb(241,105,167);
    }
    50% {
      -o-transform: rotateY(0deg);
      background: rgb(206,129,237);
    }
    100% {
      -o-transform: rotateY(90deg) perspective(113px);
      -o-transform-origin: 100% 50%;
      background: rgb(241,105,167);
    }
  }

  @-ms-keyframes cssload-cuve {
    0% {
      -ms-transform: rotateY(-90deg) perspective(113px);
      background: rgb(241,105,167);
    }
    50% {
      -ms-transform: rotateY(0deg);
      background: rgb(206,129,237);
    }
    100% {
      -ms-transform: rotateY(90deg) perspective(113px);
      -ms-transform-origin: 100% 50%;
      background: rgb(241,105,167);
    }
  }

  @-webkit-keyframes cssload-cuve {
    0% {
      -webkit-transform: rotateY(-90deg) perspective(113px);
      background: rgb(241,105,167);
    }
    50% {
      -webkit-transform: rotateY(0deg);
      background: rgb(206,129,237);
    }
    100% {
      -webkit-transform: rotateY(90deg) perspective(113px);
      -webkit-transform-origin: 100% 50%;
      background: rgb(241,105,167);
    }
  }

  @-moz-keyframes cssload-cuve {
    0% {
      -moz-transform: rotateY(-90deg) perspective(113px);
      background: rgb(241,105,167);
    }
    50% {
      -moz-transform: rotateY(0deg);
      background: rgb(206,129,237);
    }
    100% {
      -moz-transform: rotateY(90deg) perspective(113px);
      -moz-transform-origin: 100% 50%;
      background: rgb(241,105,167);
    }
  }

  .overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9998;
    background: rgb(0 0 0 / 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
</style>