import fetch from 'node-fetch'

import { ChainId } from '@uniswap/sdk'
import { ChainId as ChainIdBsc } from '@pancakeswap/sdk'

export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const tryElse = async (fn: Function, errValue?: any) => {
  try {
    return await fn()
  } catch (err) {
    console.error('[tryElse] Caught error: ', err)
    return await errValue
  }
}

export async function retry(fn: any, retryDelay = 100, numRetries = 3) {
    for (let i = 0; i < numRetries; i++) {
        try {
            return await fn()
        }
        catch (e) {
            if (i === numRetries - 1) throw e
            await timeout(retryDelay)
            retryDelay = retryDelay * 2
        }
    }
}

export const fetchETHPrice = async () => {
  const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum'

  const result = await fetch(url)

  const json = await result.json()

  return json[0]
}

export const fetchBNBPrice = async () => {
  const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin'

  const result = await fetch(url)

  const json = await result.json()

  return json[0]
}


export const fetchNativePrice = async (chainId: number) => {
  if (chainId === 1) {
    return fetchETHPrice()
  } else {
    return fetchBNBPrice()
  }
}
