import Vue from "vue";
import Vuex from "vuex";
import BigNumber from "bignumber.js";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import { PLACEHOLDER_CURRENCIES_INFO } from "@/coins";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        coins: PLACEHOLDER_CURRENCIES_INFO,
        total: new BigNumber(0),
        balanceChartData: [],
        buyTokenBalances: [],
        virtualPrice: 0,
        adminFee: 0,
        h24Volume: 0,
        totalVolume: 0,
        fee: 0,
        a: 0,
        gasPrice: {},
        // TODO: rename to "balance"
        setLiquidity3PoolToken: '',
        sigTokenBalance: '',
        sigTokenTotalSupply: 0,
        unclaimedSigTokenBalance: '',
        availableSigTokenClaims: [],
        sigTokenPrice: '',
        client: {
            balances: {},
            totalBalance: new BigNumber(0),
            // totalSupply: 0,
        },
        isLoading: false
    },
    mutations,
    actions,
    getters,
    modules: {}
});
