<template>
  <b-modal hide-header hide-footer v-model="showAlert2">
    <img src="@/assets/icons/close.svg" @click="showAlert2 = false" class="modal-close-btn" alt="">
    <div class="transaction-modal-title">{{ alertTitle }}</div>
    <div class="text-center">{{ alertText }}</div>
    <div class="text-center mt-3" v-if="!isPending && !transactionError">
      <img width="60px" src="@/assets/icons/loading-val.svg" alt="">
    </div>
    <div class="text-center mt-3" v-if="isPending && !transactionError">
      <img width="60px" src="@/assets/icons/ok.gif" alt="">
      <!-- TODO: show tx link here -->
    </div>
    <div class="text-center mt-3" v-if="transactionError">
      <img width="60px" src="@/assets/icons/error.svg" alt="">
    </div>
  </b-modal>
</template>

<script>
    export default {
        name: "Alert",
        data: () => ({
            showAlert2: false
        }),
        props: {
            showAlert: { required: true },
            isPending: { required: true },
            transactionError: { required: true },
            alertTitle: { required: true },
            alertText: { required: true },
        },
        mounted () {
            this.showAlert2 = this.showAlert
        }
    }
</script>

<style scoped>

</style>