import Web3 from "web3";
import { AbiItem } from 'web3-utils';
import BigNumber from "bignumber.js";
import ERC20 from '../abis/ERC20.json';
import { Currency } from "./types";

export const precision = (decimals: number) => {
  return new BigNumber(10).pow(decimals).toNumber();
}

export const initToken = async (web3: Web3, tokenAddress: string, index?: number): Promise<Currency> => {

    const contract = new web3.eth.Contract(ERC20.abi as AbiItem[], tokenAddress);

    const name = await contract.methods.name().call();
    const symbol = await contract.methods.symbol().call();
    const decimals = await contract.methods.decimals().call();
    const balance = new BigNumber(0);

    console.log('name', name);
    console.log('symbol', symbol);
    console.log('address', tokenAddress);
    console.log('precision', precision(decimals));

    return {
      name,
      symbol,
      precision: precision(decimals),
      address: tokenAddress,
      index,
      contract,
      balance
    }
}
