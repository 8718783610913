import BigNumber from "bignumber.js";

import { IndexedCurrencyInfo } from "@/types";
import { selectedNetwork } from "@/network";

export const PLACEHOLDER_CURRENCIES_INFO: IndexedCurrencyInfo[] = 
    selectedNetwork.chain === "ETH"
    ? [
        { index: 0, symbol: 'DAI', name: 'dai', precision: 1e18, address: '', balance: new BigNumber(0) },
        { index: 1, symbol: 'USDC', name: 'usdc', precision: 1e6, address: '', balance: new BigNumber(0) },
        { index: 2, symbol: 'USDT', name: 'usdt', precision: 1e6, address: '', balance: new BigNumber(0) },
    ] : [
        { index: 0, symbol: 'BUSD', name: 'busd', precision: 1e18, address: '', balance: new BigNumber(0) },
        { index: 1, symbol: 'USDC', name: 'usdc', precision: 1e18, address: '', balance: new BigNumber(0) },
        { index: 2, symbol: 'USDT', name: 'usdt', precision: 1e18, address: '', balance: new BigNumber(0) },
    ];
