import Web3 from "web3";
import BigNumber from "bignumber.js";
import * as Uniswap from '@uniswap/sdk'
import * as Pancakeswap from '@pancakeswap/sdk'
import { BaseProvider } from '@ethersproject/providers';

import { fetchETHPrice, fetchNativePrice, tryElse } from './helpers';
import { initToken } from './initToken'
import { Address, Currency, Contract } from './types';
import { USE_MAINNET } from '../backend/constants';

const BLOCKS_PER_YEAR = 6500 * 365; // 6500 = 86400 / 13 = approx blocks per day
const ETH_PRICE_ESTIMATE = 4200 // USD

export const fetchTokenPriceUniswap = async (lib: any, chainId: number, provider: BaseProvider, token: Currency) => {
  const { Token, WETH, Fetcher, Route, Fraction } = lib

  // const chainId = provider._network.chainId;

  const decimals = await token.contract.methods.decimals().call();

  const SIG = new Token(chainId, token.address, decimals, token.symbol, token.name)

  const pair = await Fetcher.fetchPairData(SIG, WETH[SIG.chainId], provider)

  console.log('pair', pair.liquidityToken.address)
  console.log('pair chain id', pair.liquidityToken.chainId)

  const route = new Route([pair], WETH[SIG.chainId])

  const price = route.midPrice.invert()
  
  console.log('SIG/ETH price', price.toSignificant(6))

  // if you multiply this by ETH price, you will get SIG price in $

  const { current_price: ETH_PRICE } = await tryElse(() => fetchNativePrice(chainId), ETH_PRICE_ESTIMATE)

  const ETH = new Fraction( Math.round(ETH_PRICE * 1e3).toFixed(0), 1e3.toFixed(0) );

  // TODO: put USD value
  const sigPrice = price.raw.multiply( ETH ); // 1 ETH = 1600 USD

  console.log('SIG =', sigPrice.toSignificant(6), '$');
  console.log('SIG =', sigPrice.toFixed(2), '$');

  return price
}

export const fetchTokenPriceToETH = async (provider: BaseProvider, token: Currency) => {

  const chainId = provider._network.chainId;

  if (chainId == Uniswap.ChainId.MAINNET || chainId == Uniswap.ChainId.RINKEBY) {
    return fetchTokenPriceUniswap(Uniswap, chainId, provider, token)
  } if (chainId == Pancakeswap.ChainId.MAINNET || chainId == Pancakeswap.ChainId.TESTNET) {
    return fetchTokenPriceUniswap(Pancakeswap, chainId, provider, token)
  } else {
    throw new Error('SIG has no price on this chain yet, chainId = ' + chainId)
  }
}


export const calcPoolRewardUsdYear = async (web3: Web3, provider: BaseProvider, sigToken: Currency, rewardsContract: Contract, poolRewardWeight: number) => {
//   if (!isReady) { throw new Error('Not ready') }

  const chainId = provider._network.chainId;

  const t = await web3.eth.getBlockNumber();
  
  const timeFrame = 1000; // blocks
  
  const totalRewardAtBlock_ = await rewardsContract.methods.totalRewardAtBlock(t + timeFrame).call();
  const totalRewardAtBlock = await rewardsContract.methods.totalRewardAtBlock(t).call();
  
  const totalRewardPerBlock = new BigNumber(totalRewardAtBlock_).minus(totalRewardAtBlock).div(timeFrame);

  console.log('totalRewardPerBlock', totalRewardPerBlock.toFixed(2))
  
  const sigPrice = await fetchTokenPriceToETH(provider, sigToken)

  const { current_price: ETH_PRICE } = await tryElse(() => fetchNativePrice(chainId), ETH_PRICE_ESTIMATE)

  const poolUsdPerBlock = totalRewardPerBlock
    .times(60).div(100)
    .times(poolRewardWeight)
    .times(sigPrice.toSignificant(6))
    .times(ETH_PRICE); // TODO: add ETH price
  
  console.log('poolUsdPerBlock', poolUsdPerBlock.toFixed(2))
  
  const poolRewardUsdYear = (poolUsdPerBlock.toNumber() * BLOCKS_PER_YEAR) // / 14) * 60 * 60 * 24 * 365;
  console.log('poolRewardUsdYear', poolRewardUsdYear)
  
  return poolRewardUsdYear
}

export const calculateAPYPool1 = async (web3: Web3, provider: BaseProvider, sigToken: Currency, rewardsContract: Contract, rewardsContractAddress: Address, swapContract: Contract) => {
//   if (!isReady) { return }
  try {

    console.log('calculateAPYPool1: using provider', provider._network)

    const poolInfo = await rewardsContract.methods.poolInfo(0).call();
  
    console.log('pool =', poolInfo)
  
    const lpToken = await initToken(web3, poolInfo.lpToken);
  
    const poolStakedBalance = await lpToken.contract.methods.balanceOf(rewardsContractAddress).call()
  
    const lpTokenPrice = await swapContract.methods.get_virtual_price().call()
  
    const rewardPoolUsdValue = new BigNumber(poolStakedBalance).times(lpTokenPrice).div(lpToken.precision)
  
    console.log('poolUsdValue', rewardPoolUsdValue.toFixed(2))
    
    const poolRewardWeight = poolInfo.allocPoint / await rewardsContract.methods.totalAllocPoint().call();
    
    // const sigPriceEth = await fetchTokenPriceToETH(sigToken)
    
    const poolRewardUsdYear = await calcPoolRewardUsdYear(web3, provider, sigToken, rewardsContract, poolRewardWeight)
    console.log('poolRewardUsdYear', poolRewardUsdYear)
    
    const APY = poolRewardUsdYear / rewardPoolUsdValue.toNumber() * 100 / 60 // HACK
  
    console.log('APY', APY)
    console.log('APY', new BigNumber(APY * 100).toFormat(2), '%')
  
    return APY // multiply by 100 to get %
  } catch (err) {
    console.error(err)
  }
}

export const calculateAPYPool2 = async (web3: Web3, provider: BaseProvider, sigToken: Currency, rewardsContract: Contract, rewardsContractAddress: Address) => {
  try {

    const chainId = provider._network.chainId;

    const poolInfo = await rewardsContract.methods.poolInfo(1).call();
  
    console.log('pool =', poolInfo)
  
    const uniToken = await initToken(web3, poolInfo.lpToken)
  
    // totalLpSupply =  erc20(uni-lp).totalSupply()
    const totalLpSupply = await uniToken.contract.methods.totalSupply().call()
  
    // reserveSig = sig.balanceOf(uniswap.sigethPair.address)
    const reserveSig = await sigToken.contract.methods.balanceOf(poolInfo.lpToken).call()
  
    // uniswapPairValueUsd = reserveSig*sigPrice*2
    const sigPriceEth = await fetchTokenPriceToETH(provider, sigToken)
    console.log('sigPriceEth', sigPriceEth.toSignificant(6))
  
    const uniswapPairValueUsd = new BigNumber(reserveSig).times(sigPriceEth.toSignificant(6)).times(2)
    console.log('uniswapPairValueUsd', uniswapPairValueUsd.toNumber())
  
    // lpPrice = uniswapPairValueUsd / totalLpSupply
    const lpPrice = uniswapPairValueUsd.div(totalLpSupply)
  
    console.log('lpPrice', lpPrice.toNumber())
  
    // rewardStakeLpValue = lpPrice * erc20(uni-lp).balanceOf(masterChef.address)
    const stakedUni = await uniToken.contract.methods.balanceOf(rewardsContractAddress).call()
  
    const { current_price: ETH_PRICE } = await tryElse(() => fetchNativePrice(chainId), ETH_PRICE_ESTIMATE)

    const rewardStakeLpValue = lpPrice.times(stakedUni).times(ETH_PRICE) // ETH
  
    console.log('rewardStakeLpValue', rewardStakeLpValue.toFormat(2))
    
    const poolRewardWeight = poolInfo.allocPoint / await rewardsContract.methods.totalAllocPoint().call();
  
    console.log('poolrewardweight', poolRewardWeight)
  
    const poolRewardUsdYear = await calcPoolRewardUsdYear(web3, provider, sigToken, rewardsContract, poolRewardWeight)
    
    const APY = poolRewardUsdYear / rewardStakeLpValue.toNumber() * 100 / 60 // HACK
  
    console.log('APY', APY)
    console.log('APY', new BigNumber(APY * 100).toFormat(2), '%')
  
    return APY // multiply by 100 to get %
  } catch (err) {
    console.error(err)
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const calculateAPYPool3 = async (web3: Web3, provider: BaseProvider, sigToken: Currency, rewardsContract: Contract, rewardsContractAddress: Address) => {
  try {
    // APY pool 3 for sig:
    // deposit 1 SIG from multisig
    
    const sigStaker = USE_MAINNET
      ? '0xd730D6e5d97f78101bDe4045bBb946E0014947cC'
      : '0x8716D1e0bC3f7f9eC42D784ef95B1F776f067cBD'

    // sigRewardPerBlock = (pendingSushi2(2, multisig, t+dt) -  pendingSushi2(2, multisig, t))/dt;
    const dt = 4;
    const t = await web3.eth.getBlockNumber()

    const currentPendingReward = await rewardsContract.methods.pendingSushi2(3, sigStaker, t).call()
    const futurePendingReward = await rewardsContract.methods.pendingSushi2(3, sigStaker, t+dt).call()

    console.log('t', t, currentPendingReward, futurePendingReward)
    const sigRewardPerBlock = (futurePendingReward - currentPendingReward) / dt / 1e18

    // sigRewardPerYear = sigRewardPerBlock * BLOCKS_PER_YEAR;
    const sigRewardPerYear = sigRewardPerBlock * BLOCKS_PER_YEAR

    /* eslint-disable @typescript-eslint/camelcase */
    const FIX_sigRewardPerYear = sigRewardPerYear * 10 / 6

    // return apy = sigRewardPerYear / 1
    const APY = FIX_sigRewardPerYear / 1

    return APY
  } catch (err) {
    console.error(err)
  }
}

