

























import { Component, Vue } from "vue-property-decorator";
import { selectedNetwork } from "@/network";

// HACK: import doesn't work somehow
// eslint-disable-next-line @typescript-eslint/no-var-requires
const closeIcon = require('@/assets/icons/close.svg');

const explorer = selectedNetwork.explorers[0] || null;

export type TransactionStatus = string; // | ((template: string) => string);

export const TRANSACTION_STATUS: { [key: string]: TransactionStatus } = {
    checkingForApprove: `Checking for approval`,
    approveFailed: 'Approval check failed',
    created: 'Transaction Created',
    sent: 'Transaction sent to blockchain',
    withdrawCompleted: 'Liquidity withdraw completed',
    exchanged: 'Tokens exchanged',
    mined: 'Transaction Mined',
    liquidityAdded: 'Liquidity added',
    approved: 'Approved',
    staked: 'Tokens was staked',
    unstake: 'Tokens was unstaked',
    diceRolled: 'Dice was rolled',
    stakeReverted: 'Transaction has been reverted by the EVM',
    wrong: 'Something went wrong',
    errorMessage: `Something went wrong: `,
    waitingForConfirmationTitle: 'Waiting for confirmation',
    waitingForConfirmationText: 'Please sign in your wallet',
    slippageReverted: 'Execution reverted: Too big slippage',
    burnedSig: `Burned $ SIG`,
}

export const txError = (status: TransactionStatus, hash: string, errorMessage = '') => ({
    group: 'popup',
    type: 'error',
    title: status,
    text: `
        ${errorMessage}
    `,
    duration: 8000,
    data: { hash, isSuccess: false },
})

export const txSuccess = (status: TransactionStatus, hash: string) => ({
    group: 'popup',
    type: 'success',
    title: status,
    text: ``,
    duration: 8000,
    data: { hash, isSuccess: true },
})

export const plain = (status: TransactionStatus, text = '') => ({
    group: 'popup',
    type: 'success',
    title: status,
    text: text,
    duration: 8000,
})

export const plainError = (status: TransactionStatus, text = '') => ({
    group: 'popup',
    type: 'error',
    title: status,
    text: text,
    duration: 8000,
})

@Component({
    name: "Popup",
    data: () => ({
        closeIcon,
        explorer,
    }),
})
export default class Popup extends Vue {
    static tx = { error: txError, success: txSuccess };
    static plain = { error: plainError, success: plain };
}
