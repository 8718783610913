















































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import state, { isLoadedState } from "@/contract";
import { selectedNetwork, switchNetwork, AVAILABLE_NETWORKS } from "@/network";
import filtersMixin from "@/mixins/filters";
import { ClaimInfo } from "@/services/airdrop/types/api";
import { claimTokens } from "@/services/airdrop/crypto";

import Popup, { TRANSACTION_STATUS } from "@/components/UI/Popup.vue";
import Alert from "@/components/UI/Alert.vue";

@Component({
    name: "Navbar",
    mixins: [filtersMixin],
    components: {
        Alert,
    },
    data: () => ({
        defaultAccount: state.defaultAccount,
        navs: [
            { url: "/", title: "Exchange" },
            { url: "/deposit", title: "Deposit" },
            { url: "/stake", title: "Rewards" },
            { url: "/about", title: "About" },
            { url: "/stats", title: "Stats" },
        ],
    }),
    watch: {
        '$route.path': function() {
            console.log('this.$router.currentRoute', this.$route.path)
        }
    },
    computed: {
        ...mapGetters({
            isConnected: 'getIsConnected',
            connectedAccount: 'getConnectedAccount',
            shrinkedAddress: 'getShrinkedAccount',
            sigTokenBalance: 'getSigTokenBalance',
        }),
         ...mapState([
            'total',
            'fee',
            'adminFee',
            'sigTokenPrice',
            'sigTokenTotalSupply',
            'unclaimedSigTokenBalance',
            'availableSigTokenClaims',
        ]),
        transactionPending: () => state.transactionPending,
        currentRoute: function() { return this.$route.path }
    },
    methods: {
        ...mapActions([
            'CONNECT_WALLET',
            'CHANGE_WALLET',
            'GET_SIG_TOKEN',
            'CHECK_UNCLAIMED_SIG',
        ]),
    },
})
export default class Navbar extends Vue {
    $copyText!: any;
    GET_SIG_TOKEN!: any;
    CHECK_UNCLAIMED_SIG!: any;
    availableSigTokenClaims!: any;
    currentRoute!: any;

    private showAlertCopied = false;
    private showAlert = false;
    private isSettingsOpen = false;
    private isChangeNetworkOpen = false;
    private transactionError = false;
    private isClaimPending = false;
    private alertText = '';
    private alertTitle = '';

    private explorer = selectedNetwork.explorers[0] || null;

    private selectedNetwork = selectedNetwork;
    private networks = AVAILABLE_NETWORKS;

    mounted() {
        setInterval(() => {
            this.GET_SIG_TOKEN()
            // this.CHECK_UNCLAIMED_SIG()
        }, 60000)
    }

    get selectedNetworkClassName () {
        if (this.selectedNetwork.network !== 'mainnet') {
            return 'testnet'
        } else {
            return this.selectedNetwork.chain.toLowerCase()
        }
    }

    checkRoute(name: string) {
        const cr = this.currentRoute.replace(/\//g, '')
        const rn = name.replace(/\//g, '')

        return rn.length ? cr.includes(rn) : false
    }

    copyToken () {
        if (!state.isReady) { throw new Error("State Not Loaded yet")}

        this.showAlertCopied = true;
        this.$copyText(state.defaultAccount || '')
    }

    closeSettingsModal () {
        if (this.isSettingsOpen) this.isSettingsOpen = false
    }

    async openChangeNetworkModal() {
        console.log('change network modal')

        this.isChangeNetworkOpen = true;
    }

    closeChangeNetworkModal() {
        this.isChangeNetworkOpen = false;
    }

    async changeNetwork(chainId: number) {
        console.log('change network', chainId)

        this.isChangeNetworkOpen = false;

        switchNetwork(chainId)
    }

    async claim (event: Event) {
        event.stopPropagation();

        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet")}

        const claimInfos: ClaimInfo[] = this.availableSigTokenClaims

        if (!claimInfos) {
            console.error("Nothing to claim")
            return
        }

        for (const claim of claimInfos) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.alertTitle = TRANSACTION_STATUS.waitingForConfirmationTitle;
                this.alertText = TRANSACTION_STATUS.waitingForConfirmationText;
                this.showAlert = true;

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const result = claimTokens(state.web3!, state.defaultAccount!, claim)

                result.on('transactionHash', (hash: string) => {
                    console.log('tx hash', hash)
                    this.alertTitle = '';
                    this.alertText = TRANSACTION_STATUS.created;
                    this.showAlert = true;
                    this.isClaimPending = true;
                    setTimeout(() => {
                        this.showAlert = false
                    }, 1400)
                    state.transactionPending = true;
                })
                result.on('receipt', (receipt: any) => {
                    console.log('tx hash', receipt.transactionHash)

                    this.$notify(Popup.tx.success(TRANSACTION_STATUS.mined, receipt.transactionHash))

                    this.isClaimPending = false;
                    state.transactionPending = false;

                    this.$store.dispatch('UPDATE_BALANCES')
                })
                result.on('error', (error: any) => {
                    console.error('error', error)
                    state.transactionPending = false;
                    this.isClaimPending = false;
                    this.transactionError = true
                    this.showAlert = true;
                    this.alertText = TRANSACTION_STATUS.stakeReverted
                    setTimeout(() => {
                        this.showAlert = false
                        this.transactionError = false
                    }, 2000)
                })

            } catch (err) {
                console.error('err', claim, err)
            }
        }

    }
}
