/* eslint @typescript-eslint/no-var-requires: "off" */
import Vue from 'vue';
import Notifications from 'vue-notification';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import VueAmplitude from 'vue-amplitude-js';
const vClickOutside = require('v-click-outside');
const VueGoogleCharts = require('vue-google-charts');

import App from './App.vue';
import './registerServiceWorker';
import { AMPLITUDE_API_KEY } from './constants';
import router from './router';
import store from './store';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import clickOutside from '@/directives/click-outside';

import './assets/main.scss';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications);
Vue.use(VueGoogleCharts);
Vue.use(VueClipboard);
Vue.use(vClickOutside);
Vue.use(VueAmplitude, { apiKey: AMPLITUDE_API_KEY });

Vue.component('breadcrumbs', Breadcrumbs);

new Vue({
    router,
    store,
    directives: {
        clickOutside,
    },
    render: h => h(App),
}).$mount('#app');
