import BigNumber from "bignumber.js";
import state from "@/contract";
import { MAX_ALLOWANCE_AMOUNT } from "@/constants";
import { Address, GasPrice } from "@/types";
import { initToken } from "@/init";

export const shrinkedAddress = (address: string) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const precision = (decimals: number) => {
  return new BigNumber(10).pow(decimals).toNumber();
}

export function setTimeoutPromise(delay: number) {
    return new Promise(resolve => setTimeout(resolve, delay))
}

export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const tryElse = async (fn: Function, errValue?: any) => {
  try {
    return await fn()
  } catch (err) {
    console.error('[tryElse] Caught error: ', err)
    return await errValue
  }
}

export async function retry(fn: any, retryDelay = 100, numRetries = 3) {
    for (let i = 0; i < numRetries; i++) {
        try {
            return await fn()
        }
        catch (e) {
            if (i === numRetries - 1) throw e
            await setTimeoutPromise(retryDelay)
            retryDelay = retryDelay * 2
        }
    }
}

export const checkOrApprove = async (tokenAddress: Address, toContract: Address, amount: BigNumber, isInfinite = false, gasPrice: GasPrice) => {

    if (!state.isReady) { return false }

    console.log('check allowance');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const token = await initToken(state.web3, tokenAddress);

    console.log('token', token);
    console.log('tokenAddress', tokenAddress);
    console.log('toContract', toContract);

    // const tokenContract = await state.swapContract.methods
    const currentAllowance = await token.contract.methods
      .allowance(state.defaultAccount, toContract)
      .call();

    // console.log('status: status:', await state.web3.eth.getTransactionReceipt(toContract._address))

    console.log('amount', amount.toFixed(0));
    console.log('currentAllowance', token.symbol, currentAllowance);

    // if (amount > currentAllowance) {
    //   if (isInfinite) {
    //     // send infinite
    //   } else {
    //     // send
    //   }
    // } else {
    //   if (isInfinite) {
    //     // do not send?
    //   } else {
    //     // do not send
    //   }
    // }

    if (amount.gt(currentAllowance)) {

        if (isInfinite) {
          amount = MAX_ALLOWANCE_AMOUNT;
        }

        console.log('isInfinite', isInfinite)
        console.log('amount', amount.toFixed(0))

        const gasLimit = await token.contract.methods.approve(toContract, amount.toFixed(0)).estimateGas({
          from: state.defaultAccount
        })

        console.log('gasLimit', gasLimit)

        const result = await token.contract.methods
          .approve(toContract, amount.toFixed(0))
          .send({
            from: state.defaultAccount,
            gasPrice,
            gasLimit: 100_000,
          });

        console.log('result', result);
    }

    return true
}

export const fetchETHPrice = async () => {
  const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum'

  const result = await fetch(url)

  const json = await result.json()

  return json[0]
}

export const fetchBNBPrice = async () => {
  const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin'

  const result = await fetch(url)

  const json = await result.json()

  return json[0]
}

export const getEthPrice = async (chain: string) => {
  if (chain === 'ETH') {
    const eth = await retry(fetchETHPrice, 100, 3)
    console.log('eth', eth)
    state.ethPrice = eth.current_price;
    return eth.current_price;
  } else if (chain === 'BSC') {
    const bsc = await retry(fetchBNBPrice, 100, 3)
    state.ethPrice = bsc.current_price;
    return bsc.current_price;
  }

  return 0;
}

export const formatUSD = (value: BigNumber) => {
  const options = { minimumFractionDigits: 0, maximumFractionDigits: 2 }
  return value.toNumber().toLocaleString('en-US', options)
}

export const getImgUrl = (name: any) => {
  const images = require.context('@/assets/icons/', false, /\.svg$/)
  return images(`./${name.toLowerCase()}.svg`)
}