













import {mapActions, mapState, mapMutations} from "vuex";
import { Component, Vue } from "vue-property-decorator";
import Navbar from "@/components/Navbar/Index.vue";
import Preloader from "@/components/UI/Preloader.vue";
import Footer from "@/components/Footer/Index.vue";
import Popup from "@/components/UI/Popup.vue";

@Component({
    components: {
        Footer,
        Navbar,
        Preloader,
        Popup,
    },
    methods: {
        ...mapActions([
            'CONNECT_WALLET'
        ]),
        ...mapMutations([
          'setLoading'
        ])
    },
    computed: {
        ...mapState([
          'isLoading',
          'balanceChartData'
        ])
    },
    watch: {
        balanceChartData: function(val) {
            if(val) {
                (this as App).setLoading(false)
            }
        }
    },
})
export default class App extends Vue {
    private CONNECT_WALLET!: any;
    private setLoading!: any;

    created () {
        this.checkBlocked()
        this.CONNECT_WALLET()
    }

    async checkBlocked () {
        try {
            const { isBlocked } = await fetch('/api/ip').then(res => res.json())

            console.log('is blocked', isBlocked)

            if (isBlocked) {
                window.alert('Sorry, this website is blocked in your region')
                window.location.replace('https://xsigma.com')
            }
        } catch (err) {
            console.error('Error checking for IP block')
        }
    }
}
