import { ChainId } from "@uniswap/sdk";
import { ContractConfig } from "../crypto/types";

export const ETH_MAINNET = ChainId.MAINNET;
export const ETH_TESTNET = ChainId.RINKEBY;
export const BSC_MAINNET = 56;
export const BSC_TESTNET = 97;

export const MAINNET = [
  ETH_MAINNET,
  BSC_MAINNET,
]

export const TESTNET = [
  ETH_TESTNET,
  BSC_TESTNET,
]

export const CONTRACT_ADDRESS: { [key: number]: ContractConfig } = {
    // Deploy 24.02.2021 01:55
    [ETH_TESTNET]: {
      dai: '0xC1c3015E10BB7dED5bA72ED0F5948Ca08715407d',
      usdc: '0x4B0C74e6208f09e412183799D0E15914253831fF',
      usdt: '0x78d40aa1f5Aa698B53d2A9e1f58825B0Fc4165bd',
      sigToken: '0x77777777778E9F1259A32f4d605aDDe67d576c79',
      timeLock: '0x5e72DaaE5555D8359c1b53750CA80c0F984a2206',
      governor: '0xd8c589E90044Fc1eCaB6b440E8eEb95e0b60e4c7',
      devVault: '0x04765a13E7df490b10B37706d7b50c00dc5141ae',
      corpVault: '0xad762977e88e67e7144B1c28E8CCfD4f66C88FD4',
      periodicDutchAuction: '0x5Ea80CD7691d299Fd2F9644290de513Eb66F25bA',
      curveLpToken: '0xEc78baf121a59ff201C386d8d35ea1544a05A7d7',
      curve3pool: '0x2aA7ac7208fB0ced5463b134A794E11A49eBBd42',
      xsigmaLpToken: '0xAf8d68e58499E86F25de8722A8868895d500C306',
      xsigma3poolDelegator: '0x3333333ACdEdBbC9Ad7bda0876e60714195681c5',
      sigMasterChef: '0xb93D6f1F7FEf8f5dCFA2d1F2f7c29eA72eA9b275',
      lottery: '0xFFfFfffff3471BacB75460D390474fCE40B7de42', // has +1 day bug
    },
    [ETH_MAINNET]: {
      dai: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
      usdc: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      usdt: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      sigToken: '0x7777777777697cFEECF846A76326dA79CC606517', 
      timeLock: '',
      governor: '',
      devVault: '0x31933fcb39452351f0D708370fB3A81695c6C5fB',
      corpVault: '0x4C34a635C18149C4757dE73F9a7D4A9EF4678116',
      periodicDutchAuction: '0xb843B122ac2Ff261F425bf0F639b5718e25C0691',
      curveLpToken: '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490',
      curve3pool: '0xbEbc44782C7dB0a1A60Cb6fe97d0b483032FF1C7',
      xsigmaLpToken: '0x88E11412BB21d137C217fd8b73982Dc0ED3665d7',
      xsigma3poolDelegator: '0x3333333ACdEdBbC9Ad7bda0876e60714195681c5',
      sigMasterChef: '0x98C32b59a0AC00Cd33750427b1A317eBcf84D0F7',
      lottery: '0xFFfFFFfFa099A386064b24a862e5d93eF7267569',
    },
    [BSC_TESTNET]: {
        // NAME: "bsc_testnet",

        // TEAM_DEVELOPER_1: "0xF2A961aF157426953e392F6468B0162F86B2aCBC",
        // TEAM_DEVELOPER_2: "0x9ebf6F16C0dAd9F92eaAcA8dBd40944E614338ae",
        // TEAM_OWNER: "0xF2A961aF157426953e392F6468B0162F86B2aCBC",
        // TEAM_LAWYER: "0xF2A961aF157426953e392F6468B0162F86B2aCBC",
        // TEAM_MULTISIG: "0xF2A961aF157426953e392F6468B0162F86B2aCBC",

        // dai: "0x1B7348b958D9b973bD7A8210Cc1605aCaf2c0179",
        dai: "0x6EC4484df38abE4a6E17d3566eCc8ad06131187B",
        usdc: "0xcf21ecF6678dbfF9E68095224F9cC43AD1983771",
        usdt: "0xf4B41a53b67652E83f59df082F3CA342252b7E20",
          //
        sigToken: "0xd24aF577CfF4EeA8014397b970097767678fBe6d",
          //
        devVault: "0xe18E9864Bac50B16E0FC2B1378D094d10f0E333b",
        corpVault: "0x7716B90c337963927a1C34e4A8c8bC8F476a620c",
        periodicDutchAuction: "0x01776c9bE593D79a00a50B796333b2a1D856fE7d",
        //
        curveLpToken: "0x3a3887f087DCd54Ee134AF5CbEa2c5C89d16D7DE",
        curve3pool: "0xabdBef4553489d31830bc8265C8EC3aBd4e86D39",
        //
        xsigmaLpToken: "0xc891b284F8C1D042A7a99897439e6FB070904Bc5",
        xsigma3poolDelegator: "0xE773158F595c505F86571C7bEd004Da79310DBAd",
        // cashBack: "0x37B607c9f567a092852cB788Db2D4758715A7519",
        //
        sigMasterChef: "0x7c2C77971e5Aa4d691020bA50ce70ac907456153",

        // deployedContracts[bsc_testnet] {
        //   DAI: '0x6EC4484df38abE4a6E17d3566eCc8ad06131187B',
        //   USDC: '0xcf21ecF6678dbfF9E68095224F9cC43AD1983771',
        //   USDT: '0xf4B41a53b67652E83f59df082F3CA342252b7E20',
        //   SIG_TOKEN: '0xd24aF577CfF4EeA8014397b970097767678fBe6d',
        //   TIME_LOCK: '',
        //   GOVERNOR: '',
        //   DEV_VAULT: '',
        //   CORP_VAULT: '',
        //   AUCTION: '0x01776c9bE593D79a00a50B796333b2a1D856fE7d',
        //   CURVE_LP_TOKEN: '0x3a3887f087DCd54Ee134AF5CbEa2c5C89d16D7DE',
        //   CURVE_3POOL: '0xabdBef4553489d31830bc8265C8EC3aBd4e86D39',
        //   SIG_LP_TOKEN: '0xc891b284F8C1D042A7a99897439e6FB070904Bc5',
        //   SIG_3POOL: '0xE773158F595c505F86571C7bEd004Da79310DBAd',
        //   CASH_BACK: '0x37B607c9f567a092852cB788Db2D4758715A7519',
        //   SIG_MASTER_CHEF: '0x7c2C77971e5Aa4d691020bA50ce70ac907456153'
        // }
  },

  [BSC_MAINNET]: {
    // DAI: "0xe9e7cea3dedca5984780bafc599bd69add087d56", // BUSD
    // USDC: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    // USDT: "0x55d398326f99059ff775485246999027b3197955",
    dai: "0xe9e7cea3dedca5984780bafc599bd69add087d56", // BUSD
    usdc: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    usdt: "0x55d398326f99059ff775485246999027b3197955",
    //
    sigToken: "0x7777777777697cFEECF846A76326dA79CC606517",

    devVault: '',
    corpVault: '',

    periodicDutchAuction: "0xddddddd4f7280e91aa5ed57d937413451cfd0946",
    //
    curveLpToken: "0xaf4de8e872131ae328ce21d909c74705d3aaf452",
    curve3pool: "0x160caed03795365f3a589f10c379ffa7d75d4e76",
    //
    xsigmaLpToken: "0x78d40aa1f5Aa698B53d2A9e1f58825B0Fc4165bd",
    xsigma3poolDelegator: "0x33333333420360CBa0e3D760182cBb3faFADceDd",
    // cashback: "0xDa59F5Ca1465D989B223e6eC4244ee1da5ed24dC",
    //
    sigMasterChef: "0xFFfFfffff3471BacB75460D390474fCE40B7de42",
  },
}

export default CONTRACT_ADDRESS
