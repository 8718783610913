import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import BigNumber from "bignumber.js";
import { Currency, CoinBalance } from "@/types";

export default {
    setLoading: (state: any, payload: boolean) => state.isLoading = payload,
    setA: (state: any, payload: string) => state.a = payload,
    setFee: (state: any, payload: string) => state.fee = payload,
    setAdminFee: (state: any, payload: string) => state.adminFee = payload,
    setVirtualPrice: (state: any, payload: string) => state.virtualPrice = payload,
    setClientBalances: (state: any, payload: { [key: string]: BigNumber }) => state.client.balances = payload,
    // setClientTotalSupply: (state: any, payload: number) => state.client.totalSupply = payload,
    setClientTotalBalance: (state: any, payload: BigNumber) => state.client.totalBalance = payload,
    setGasPrice: (state: any, payload: number) => state.gasPrice = payload,
    setLiquidity3PoolToken: (state: any, payload: string) => state.setLiquidity3PoolToken = payload,
    setSigTokenBalance: (state: any, payload: string) => state.sigTokenBalance = payload,
    setSigTokenTotalSupply: (state: any, payload: number) => state.sigTokenTotalSupply = payload,
    setUnclaimedSigTokenBalance: (state: any, payload: string) => state.unclaimedSigTokenBalance = payload,
    setAvailableSigTokenClaims: (state: any, payload: any[]) => state.availableSigTokenClaims = payload,
    setSigTokenPrice: (state: any, payload: string) => state.sigTokenPrice = payload,
    set24Volume: (state: any, payload: any) => state.h24Volume = payload,
    setTotalVolume: (state: any, payload: any) => state.totalVolume = payload,
    setBalance (state: any, payload: CoinBalance) {
        state.coins[payload.coinId].balance = new BigNumber(parseInt(payload.balance));
    },
    setTotal (state: any) {
        state.total = new BigNumber(0)
        state.coins.forEach((coin: Currency) => state.total = state.total.plus(coin.balance.dividedBy(coin.precision)));
    },
    setBalanceChartData (state: any) {
        const data = state.coins.map((coin: Currency) => {
            const b = coin.balance.dividedBy(coin.precision);
            return [
                coin.symbol,
                b.dividedBy(state.total).multipliedBy(100).toNumber(),
                b.toNumber()
            ]
        })
        state.balanceChartData = [['Name', 'Value', 'Percent'], ...data];
    }
}
