import BigNumber from "bignumber.js";
import { formatUSD } from "@/helpers";

export default {
    filters: {
        capitalize: function(str: string) {
            return str.charAt(0).toUpperCase() + str.slice(1)
        },
        // TODO: make loaders in views instead
        checkLoaded: function(n: any) {
            return (n && !new BigNumber(n).isNaN()) ? n : ' ~'
        },
        // 1000000.00 <= decimal places
        toFixed: function(n: any, decimals = 2) {
            return parseFloat(n) ? parseFloat(n).toFixed(decimals) : n
        },
        // 1,000,000.00 <= with decimal separators
        toFormat: function(n: BigNumber, decimals = 0) {
            return new BigNumber(n).toFormat(decimals)
        },
        toNumber: function (n: any) {
            return new BigNumber(n).toNumber()
        },
        // you can use .toFormat instead
        toUSD: function (n: any) {
            return new BigNumber(n) ? formatUSD(new BigNumber(n)) : n
        },
    }
}
