import BigNumber from "bignumber.js";

export const USE_MAINNET = process.env.NODE_ENV !== 'development' && !window.location.hostname.includes('testnet');

export const APP_URL = "https://app.xsigma.fi"
export const APP_NAME = "xSigma DEX"
export const CONTACT_EMAIL = "support@xsigma.com"

export const ONBOARD_API_KEY = "429ff026-bcd8-4732-b941-f3746ffc47b0";
export const INFURA_KEY = "59e12d25148c4445bde7e5c376de7ae6"
export const AMPLITUDE_API_KEY = '24fa3966fdce16e1689091440af10196';

// TODO: make a dictionary of different gas limits for different contract method calls
export const GAS_LIMIT = 300_000;

export const MAX_ALLOWANCE_AMOUNT = new BigNumber(2).pow(256).minus(1);

export const ETH_PRICE_ESTIMATE = "4200";

export const N_COINS = 3;
