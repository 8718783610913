import { USE_MAINNET } from '../../constants';

export const PROD_API_ROOT = 'https://airdrop.caffeinum.com/v0';

export const API_ROOT = PROD_API_ROOT;

export const CONTRACT_ADDRESSES = {
           rinkeby: '0x65098f9824B655D5695A44D22B988BA1296E06BC',
           mainnet: '0x0aaaaaaab9dfbc9f1e69829bdbd32ca4b02702bf',
       };

export const NETWORK_ID = USE_MAINNET ? 1 : 4;

export const CONTRACT_ADDRESS = USE_MAINNET ? CONTRACT_ADDRESSES.mainnet : CONTRACT_ADDRESSES.rinkeby