import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import BigNumber from 'bignumber.js'
import { CONTRACT_ADDRESS } from './constants'
import { Address, AirdropCreationInfo, ClaimInfo, Transaction } from './types/api'
import AIRDROPPER from './abi/AirDropper.json'
import ERC20 from './abi/ERC20.json'
import { GasPrice } from '@/types'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/camelcase */

export const checkClaim = async (web3: Web3, from: Address, claimInfo: ClaimInfo): Promise<boolean> => {
  if (!web3) {
    throw new Error('web3 not available')
  }

  // TODO: init contract only once and save
  const contract = new web3.eth.Contract(AIRDROPPER.abi as AbiItem[], CONTRACT_ADDRESS);

  const { airdrop_id, index } = claimInfo

  console.log('args', claimInfo)

  return contract.methods.isClaimed(airdrop_id, index).call()
}

export const claimTokens = (web3: Web3, from: Address, claimInfo: ClaimInfo, gasPrice?: GasPrice): Transaction => {
  if (!web3) {
    throw new Error('web3 not available')
  }
  
  // TODO: init contract only once and save
  const contract = new web3.eth.Contract(AIRDROPPER.abi as AbiItem[], CONTRACT_ADDRESS);

  const { airdrop_id, index, account, amount } = claimInfo;
  let { merkle_proof: proof } = claimInfo;

  console.log('proof', proof)

  // because can be empty string, which is invalid for web3
  proof = proof ? proof.map(p => p || '0x0') : []

  console.log('args', claimInfo)

  const result = contract.methods.claim(airdrop_id, index, account, amount, proof).send({
    from: from,
    gasPrice: gasPrice,
  })

  console.log('result', result)

  return result
}

export const addAirdrop = async (web3: Web3, from: Address, airdrop_info: AirdropCreationInfo) => {
  if (!web3) {
    throw new Error('web3 not available')
  }

  const { id, token, amount, merkleRoot } = airdrop_info

  // TODO: init contract only once and save
  const contract = new web3.eth.Contract(AIRDROPPER.abi as AbiItem[], CONTRACT_ADDRESS);

  const result = contract.methods.addAirdrop(id, token, amount.toFixed(0), merkleRoot).send({
    from: from,
  })

  console.log('result', result)

  return result
}

export const approve = async (web3: Web3, from: Address, tokenAddress: Address, amount: BigNumber) => {
  if (!web3) {
    throw new Error('web3 not available')
  }
  
  const token = new web3.eth.Contract(ERC20.abi as AbiItem[], tokenAddress);

  const result = token.methods.approve(CONTRACT_ADDRESS, amount.toFixed(0)).send({
    from: from,
  })

  console.log('result', result)

  return result
}

export const allowance = async (web3: Web3, from: Address, tokenAddress: Address) => {
  if (!web3) {
    throw new Error('web3 not available')
  }
  
  const token = new web3.eth.Contract(ERC20.abi as AbiItem[], tokenAddress);

  return token.methods.allowance(from, CONTRACT_ADDRESS).call()
}

export const decimals = async (web3: Web3, tokenAddress: Address) => {
  if (!web3) {
    throw new Error('web3 not available')
  }
  
  const token = new web3.eth.Contract(ERC20.abi as AbiItem[], tokenAddress);

  return token.methods.decimals().call()
}
