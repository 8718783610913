import Vue from "vue";
import Vuex from "vuex";
import constState from "@/contract";
import { shrinkedAddress } from "@/helpers";
import { GasPriceInfo } from "@/types";

Vue.use(Vuex);

export default {
    getIsConnected: () => {
        return !!constState.defaultAccount
    },
    getSigTokenBalance: (state: any) => {
        return state.sigTokenBalance
    },
    getConnectedAccount: () => {
        return constState.defaultAccount
    },
    getShrinkedAccount: () => {
        return constState.defaultAccount
            ? shrinkedAddress(constState.defaultAccount)
            : 'Not connected'
    },
    getGasPriceOptions: (state: any): GasPriceInfo[] => {
        return [
            { label: 'Slow', value: state.gasPrice.standard }, // because "slow" is zero
            { label: 'Fast', value: state.gasPrice.fast },
            { label: 'Instant', value: state.gasPrice.instant },
        ];
    }
}