import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/about",
        name: "About",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue")
    },
    {
        path: "/terms",
        name: "Terms",
        component: () =>
            import(/* webpackChunkName: "terms" */ "../views/Terms.vue")
    },
    {
        path: "/policy",
        name: "Policy",
        component: () =>
            import(/* webpackChunkName: "policy" */ "../views/Policy.vue")
    },
    {
        path: "/aml-policy",
        name: "AMLPolicy",
        component: () =>
            import(/* webpackChunkName: "aml-policy" */ "../views/AMLPolicy.vue")
    },
    {
        path: "/risks",
        name: "Risks",
        component: () =>
            import(/* webpackChunkName: "risks" */ "../views/Risks.vue")
    },
    {
        path: "/authorized",
        name: "Authorized",
        component: () =>
            import(
                /* webpackChunkName: "authorized" */ "../views/Authorized.vue"
            )
    },
    {
        path: "/liquidity",
        name: "Liquidity",
        component: () =>
            import(/* webpackChunkName: "liquidity" */ "../views/Liquidity.vue")
    },
    {
        path: "/withdraw",
        name: "Withdraw",
        component: () =>
            import(/* webpackChunkName: "withdraw" */ "../views/WithdrawView.vue")
    },
    {
        path: "/deposit",
        name: "Deposit",
        component: () =>
            import(/* webpackChunkName: "liquidity" */ "../views/Liquidity.vue")
    },
    {
        path: "/stake",
        name: "Rewards",
        component: () =>
            import(/* webpackChunkName: "rewards" */ "../views/Rewards.vue")
    },
    {
        path: "/stake/:poolIndex",
        name: "StakeWallet",
        component: () =>
            import(/* webpackChunkName: "stakeMetamask" */ "../components/StakePool.vue")
    },
    {
        path: "/auction",
        name: "Auction",
        component: () =>
            import(/* webpackChunkName: "auction" */ "../views/Auction.vue")
    },
    {
        path: "/fee-payout",
        name: "VRF Fee Payout",
        component: () =>
            import(/* webpackChunkName: "view" */ "../views/Lottery.vue")
    },
    {
        path: "/stats",
        name: "Stats",
        component: () =>
            import(/* webpackChunkName: "stats" */ "../views/Stats.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
