

















import { Component, Vue } from "vue-property-decorator";

@Component({
    name: "Footer",
    data: () => ({
        navs: [
            { url: '/terms', title: 'Terms of Use' },
            { url: '/aml-policy', title: 'AML Policy' },
            { url: '/policy', title: 'Privacy Policy' },
            { url: '/about', title: 'About' }
        ]
    }),
    computed: {},
    methods: {},
})
export default class Footer extends Vue {}
