import { ChainId } from "@uniswap/sdk";
import { ChainId as ChainIdBsc } from "@pancakeswap/sdk";

import CONTRACT_ADDRESS from "../src/address";

export const INFURA_KEY = "1de692836956445bbb5e2ee22b2f2778";

export const USE_MAINNET = true;

export const networkId = USE_MAINNET ? ChainId.MAINNET : ChainId.RINKEBY
// TODO: make a switch for BSC/Matic/etc
export const CONTRACT = CONTRACT_ADDRESS[networkId];
export const CONTRACT_BSC = CONTRACT_ADDRESS[ChainIdBsc.MAINNET];

export const SIG_TOKEN_ADDRESS = CONTRACT.sigToken

export const RPC_URL = USE_MAINNET
    ? `wss://mainnet.infura.io/ws/v3/${INFURA_KEY}`
    : `wss://rinkeby.infura.io/ws/v3/${INFURA_KEY}`
