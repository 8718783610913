/* eslint-disable @typescript-eslint/camelcase */
import BigNumber from 'bignumber.js'
import { API_ROOT } from './constants'
import { Address, AirdropCreatedResponse, ClaimInfo, NativeTokenAmount, Receiver, ReceiverData, TokenAmount } from './types/api'


export const createTree = async (
  token: Address,
  addressList: Receiver[],
  creator: Address,
  description?: string,
): Promise<AirdropCreatedResponse> => {
  const url = `${API_ROOT}/add-tree`

  const balances = addressList
    .map(r => ({ [r.address]: r.amount }))
    .reduce((obj, a) => ({ ...obj, ...a }), {})

  const body = JSON.stringify({
    token: token,
    balances: balances,
    creator: creator,
    description: description,
  })

  const reply = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: body,
  })

  const info = await reply.json()

  if (info.airdropInfo) {
    // TODO: better parsing
    info.airdropInfo.total_amount = new BigNumber(info.airdropInfo.total_amount)
  }

  return info as AirdropCreatedResponse
}

export const getClaimInfo = async (receiver: Address, airdrop_id = ''): Promise<ClaimInfo[]> => {

  const url = `${API_ROOT}/get-claim-info?account=${receiver}&airdrop_id=${airdrop_id}`

  const reply = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  const info = await reply.json()

  console.log('info', info)

  // info.amount = new BigNumber(info.amount)

  return info as ClaimInfo[]
}

export const parseReceiverLine = (line: string): ReceiverData => {
  try {
    const [ address, amount ] = line.split(/[^a-zA-Z0-9]+/g)

    // TODO: check address is correct

    // TODO: parse personal message too

    return { address, amount: new BigNumber(amount) as TokenAmount }
  } catch (err) {
    console.error(err)

    return { address: '0x0', amount: new BigNumber(0) as TokenAmount }
  }
}

export const toNativeTokenAmount = (amount: TokenAmount, decimals: number): NativeTokenAmount => {
  const precision = new BigNumber(10).pow(decimals)

  return amount.times(precision)
}

export const toTokenAmount = (amount: NativeTokenAmount, decimals: number): TokenAmount => {
  const precision = new BigNumber(10).pow(decimals)

  return amount.div(precision)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).api = {
  createTree,
  getClaimInfo,
}
