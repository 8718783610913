import { getDefaultProvider } from '@ethersproject/providers';

import { INFURA_KEY, USE_MAINNET } from "@/constants";
import { CONTRACT_ADDRESS, MAINNET, TESTNET, BSC_TESTNET, BSC_MAINNET, ETH_MAINNET, ETH_TESTNET } from "@/address";
import chains from "./chains.json";

export const DEFAULT_NETWORK_ID = Number(localStorage.getItem("SELECTED_NETWORK_ID")) || (USE_MAINNET ? ETH_MAINNET : ETH_TESTNET);
// export const DEFAULT_NETWORK_ID = USE_MAINNET ? BSC_MAINNET : BSC_TESTNET;
// export const DEFAULT_NETWORK_ID = USE_MAINNET ? ETH_MAINNET : ETH_TESTNET;

export const AVAILABLE_NETWORKS = chains.filter(chain => (USE_MAINNET ? MAINNET : TESTNET).includes(chain.chainId))

export const network = AVAILABLE_NETWORKS.find(chain => window.location.href.includes(chain.chain.toLowerCase()))
                                || chains.find(chain => chain.chainId === DEFAULT_NETWORK_ID);


if (!network) {
    if (localStorage.getItem('SELECTED_NETWORK_ID')) {
        localStorage.removeItem('SELECTED_NETWORK_ID')
        window.location.reload();
    }

    throw new Error('Wrong network, check the chains.json for chainId = ' + DEFAULT_NETWORK_ID)
}

// TODO: move to state
export const selectedNetwork = network;
export const RPC_URL = selectedNetwork.rpc[0].replace('${INFURA_API_KEY}', INFURA_KEY);

export const CONTRACT = CONTRACT_ADDRESS[network.chainId];

export const provider = getDefaultProvider(RPC_URL); // for Uniswa

export const isBSC = (chainId: number) => [ BSC_TESTNET, BSC_MAINNET ].includes(chainId)
export const isETH = (chainId: number) => [ ETH_TESTNET, ETH_MAINNET ].includes(chainId)

// TODO: when detecting using network, also change url
console.log('network id', network.chainId);
console.log('network', network);
console.log('contracts addresses', CONTRACT);

export const switchNetwork = async (chainId: number) => {
    const newNetwork = chains.find(chain => chain.chainId === chainId);

    if (!newNetwork) {
        throw new Error('Wrong network, check the chains.json for chainId = ' + chainId)
    }

    localStorage.setItem('SELECTED_NETWORK_ID', chainId.toString());

    window.location.reload();

    // console.log('network id', selectedNetwork.chainId);
    // console.log('network', selectedNetwork);
    // console.log('contracts addresses', CONTRACT);
}

export const switchMetamaskNetwork = async (provider: any, chainId: number) => {
    if (!provider || typeof provider === "string") {
        return
    }

    const chainIdHex = `0x${chainId.toString(16)}`;

    try {
        await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainIdHex }],
        });
    } catch (error) {
        // const error = <any>_error;
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if ((error as any).code === 4902) {
            const network = selectedNetwork;

            try {
                await provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: chainIdHex,
                            nativeCurrency: network.nativeCurrency.symbol,
                            chainName: network.name,
                            rpcUrls: network.rpc,
                            blockExplorerUrls: network.explorers,
                        },
                    ],

                });
            } catch (error) {
                console.error(error);
            }
        }
        console.error(error);
    }
}
