<template>
    <b-row class="justify-content-md-center my-5">
        <b-col xl="5" lg="6" md="8">
            <Exchange />
        </b-col>
    </b-row>
</template>

<script>
import Exchange from "@/components/Exchange";

export default {
    name: "Home",
    components: {
        Exchange
    }
};
</script>
